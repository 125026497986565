<template>
  <div class="hotel-information">
    <h3 class="sub-title">Oral Presentation</h3>
    <p>To be updated.</p>
  </div>
</template>

<script>
export default {
  components: {
  }
}
</script>

<style lang="less" scoped>
.hotel-information {
  .sub-title {
    font-weight: 900;
    font-size: 27px;
    line-height: normal;
    margin: 30px 0px 20px 0px;
    padding-bottom: 10px;
    border-bottom: solid 1px #ddd;
  }
  p {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased !important;
    font-size: 20px;
    text-align: justify;
  }
  .bottom {
    display: flex;
    .bottom-lt {
      flex: 0 0 25%;
      img {
        width: 150px;
      }
    }
    .bottom-rt {
      flex: 0 0 75%;
      img {
        height: 50px;
      }
      .pre-author {
        font-size: 24px;
        margin-bottom: 15px;
        font-weight: 700;
        display: block;
      }
      .pre-subtext {
        font-family: "PublicSans Regular";
      }
    }
  }
}
</style>
